import { useMsal } from "@azure/msal-react";
import { CSSProperties, FC, useEffect, useState } from "react"
import { AutoGenMessageRole, AutoGenMessageType, AutoGenService } from "../../services/AutoGen/AutoGenService";
import { useDispatch } from "react-redux";
import { addAlert } from "../../redux/features/app/appSlice";
import { AlertType } from "../../libs/models/AlertType";
import { AuthHelper } from "../../libs/auth/AuthHelper";
import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm';


import './autogen-page.css'
import { Divider } from "@fluentui/react-components";

const isNullOrEmpty = (x: string | null | undefined) => x == null || x.trim() == "";

type Message = ({
    type: AutoGenMessageType.Message,
    from: string,
    content: string,
} | {
    type: AutoGenMessageType.Error,
    content: string,
}) & {
    id: string;
    role: AutoGenMessageRole;
    isHidden: boolean;
}

export const AutoGenPage: FC = () => {
    const { instance, inProgress } = useMsal();
    const autoGenService = new AutoGenService();

    const dispatch = useDispatch();
    const [userInput, setUserInput] = useState<string | null>(null);

    const [messages, setMessages] = useState<Message[]>([]);

    const [chatId, setChatId] = useState<string | null>(null)
    const [sendButtonDisabled, setSendButtonDisabled] = useState(isNullOrEmpty(userInput));

    const [sendingMessage, setSendingMessage] = useState<{
        sentAt: Date,
        sentFor: number // ms
    } | null>(null);

    useEffect(() => {
        setSendButtonDisabled(sendingMessage != null || isNullOrEmpty(userInput))
    }, [userInput, sendingMessage]);

    const sendMessageAsync = async () => {
        if (isNullOrEmpty(userInput)) {
            dispatch(addAlert({
                message: "Le message ne doit pas être vide",
                type: AlertType.Error
            }));
            return;
        }

        const accessToken = await AuthHelper.getSKaaSAccessToken(instance, inProgress);
        const sentAt = new Date();
        setSendingMessage({
            sentAt: sentAt,
            sentFor: 0
        });
        const sendStateUpdaterIntervalRef = setInterval(() => {
            setSendingMessage({
                ...sendingMessage!,
                sentFor: new Date().getTime() - sentAt.getTime()
            });
        }, 100);

        try {
            const newMessages = await autoGenService.chatAsync(chatId!, {
                input: userInput!,
                variables: []
            }, accessToken)

            setMessages(newMessages.map(m => ({
                id: m.id,
                type: m.type,
                from: m.from,
                content: m.content,
                role: m.role,
                isHidden: m.isHidden
            })));
        } catch (e: any) {
            // Idée de mouise car l'affichage des alertes est fait dans une autre page
            dispatch(addAlert({
                type: AlertType.Error,
                message: e.toString()
            }))
        }
        finally {
            clearInterval(sendStateUpdaterIntervalRef);
            setSendingMessage(null);
        }

        setUserInput(null);
    }

    const createNewChat = async () => {
        const accessToken = await AuthHelper.getSKaaSAccessToken(instance, inProgress);
        if (chatId != null) {
            setChatId(null);
            await autoGenService.deleteChatAsync(chatId!, accessToken);
        }

        const conversation = await autoGenService.newChatAsync(accessToken)

        setChatId(conversation.chatId);
        setMessages(conversation.messages);
    }

    useEffect(() => {
        void createNewChat();
    }, []);

    return (
        <div style={{ height: '95svh', display: 'flex', flexDirection: 'column', padding: '1rem', boxSizing: 'border-box' }}>

            <header>
                <button onClick={() => createNewChat()}>Nouvelle conversation</button>
                <span>{messages.length} messages</span>
            </header>
            <main style={{ flexGrow: 1, overflow: 'auto' }}>

                {chatId != null
                    ? (
                        <ul style={{ all: 'unset', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                            {messages.map((m, index) => {
                                let res;
                                const messageStyle: CSSProperties = {
                                    border: '3px solid black',
                                    padding: '1rem',
                                    width: 'fit-content',
                                    float: m.role === 'user' ? 'right' : 'left'
                                }

                                if (m.type === AutoGenMessageType.Error) {
                                    messageStyle.borderColor = 'red';
                                    res = <div style={messageStyle}>
                                        <span style={{ whiteSpaceCollapse: 'preserve' }} >{m.content}</span>
                                    </div>
                                }
                                else {
                                    messageStyle.borderColor = m.isHidden ? 'gray' : 'green';
                                    const id = `message-content-show-button-${m.id}`;
                                    res = <div style={messageStyle} className="message-container">
                                        <div style={{ display: m.isHidden ? 'block' : 'none' }}>
                                            <p style={{ margin: '5px' }}>Hidden message</p>
                                            <label htmlFor={id}>Show/Hide</label>
                                            <input id={id} type="checkbox" className="message-content-show-button" defaultChecked={!m.isHidden} />
                                        </div>
                                        <div className="message-content-container">
                                            <b>From:</b>
                                            <span> {m.from}</span>
                                            <Divider />
                                            
                                            <b>Content:</b>
                                            <ReactMarkdown remarkPlugins={[remarkGfm]}>{m.content}</ReactMarkdown>
                                        </div>
                                    </div>
                                }

                                return <li key={index}>{res}</li>
                            })}
                        </ul>
                    )
                    : "Loading..."}
            </main>
            <footer style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ width: '100%' }}>
                    <textarea name="chat-input" id="chat-input"
                        style={{ all: 'unset', width: '100%', border: '1px solid black', minHeight: '10rem', boxSizing: 'border-box' }}
                        value={userInput ?? ''}
                        onChange={e => setUserInput(e.target.value)}
                        disabled={chatId == null}>
                    </textarea>

                    <div>
                        {sendingMessage && <span>{sendingMessage.sentFor}ms</span>}
                    </div>
                </div>
                <button onClick={sendMessageAsync} disabled={sendButtonDisabled}>Envoyer</button>
            </footer>
        </div>
    )
}