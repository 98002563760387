import {
    Body1,
    Button,
    Divider,
    makeStyles,
    Popover,
    PopoverSurface,
    PopoverTrigger,
    shorthands,
    tokens,
    Text,
} from '@fluentui/react-components';
import {
    InvokationTokenUsage,
    TokenUsage,
    TokenUsageFunctionOrder,
} from '../../libs/models/TokenUsage';
import { useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { TypingIndicator } from '../chat/typing-indicator/TypingIndicator';
import { Info16 } from '../shared/BundledIcons';
import { useState } from 'react';
import { TokenUsageGraphItem } from './TokenUsageGraphItem';

const useClasses = makeStyles({
    horizontal: {
        display: 'flex',
        ...shorthands.gap(tokens.spacingVerticalSNudge),
        alignItems: 'center',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.gap(tokens.spacingHorizontalS),
        paddingBottom: tokens.spacingHorizontalM,
    },
    popover: {
        width: '300px',
    },
    header: {
        marginBlockEnd: tokens.spacingHorizontalM,
    },
    legend: {
        'flex-flow': 'wrap',
    },
    divider: {
        width: '97%',
    },
});

interface ITokenUsageGraph {
    tokenUsage: TokenUsage;
    promptView?: boolean;
}

export const TokenUsageGraph: React.FC<ITokenUsageGraph> = ({ promptView, tokenUsage }) => {
    const classes = useClasses();
    const { conversations, selectedId } = useAppSelector((state: RootState) => state.conversations);


    const totalTokenUsage: InvokationTokenUsage = {
        prompt: 0,
        completion: 0,
        total: 0
    };
    const usages = Object.entries(tokenUsage)
        .map(([key, value]) => ({ name: key, usage: value }))
        .sort((a, b) => TokenUsageFunctionOrder.indexOf(a.name) - TokenUsageFunctionOrder.indexOf(b.name));

    const loadingResponse = selectedId !== ''
        && conversations[selectedId].botResponseStatus && usages.length === 0;

    usages.forEach(usage => {
        totalTokenUsage.prompt += usage.usage.prompt;
        totalTokenUsage.completion += usage.usage.completion;
        totalTokenUsage.total += usage.usage.total;
    });
    const [isChartExpanded, setIsChartExpanded] = useState(true);

    return (
        <>
            <h3 className={classes.header}>
                Token Usage
                <Popover withArrow>
                    <PopoverTrigger disableButtonEnhancement>
                        <Button icon={<Info16 />} appearance="transparent" />
                    </PopoverTrigger>
                    <PopoverSurface className={classes.popover}>
                        <Body1>
                            Token count for each category is the total sum of tokens used for the prompt template and
                            chat completion for the respective completion functions. For more details about token usage,
                            see{' '}
                            <a href="https://learn.microsoft.com/en-us/dotnet/api/azure.ai.openai.completionsusage?view=azure-dotnet-preview">
                                CompletionsUsage
                            </a>{' '}
                            docs.
                        </Body1>
                    </PopoverSurface>
                </Popover>
            </h3>
            <div className={classes.content}>
                {loadingResponse ? (
                    <Body1>
                        Final token usage will be available once bot response is generated.
                        <TypingIndicator />
                    </Body1>
                ) : (
                    <>
                        <div>
                            <input id="is-chart-expanded" type="checkbox" checked={isChartExpanded} onChange={(e) => setIsChartExpanded(e.currentTarget.checked)} />
                            <label htmlFor="is-chart-expanded">Montrer le graphique</label>
                        </div>

                        {isChartExpanded && (usages.length > 0
                            ? <>
                                <div>
                                    <b>{totalTokenUsage.total}</b> tokens utilisé dont <b>{totalTokenUsage.prompt}</b> pour le prompt et <b>{totalTokenUsage.completion}</b> de génération
                                </div>
                                {usages
                                    .map(usage => <TokenUsageGraphItem key={usage.name} name={usage.name} usage={usage.usage} globalTotalUsage={totalTokenUsage.total} />)
                                }</>
                            : promptView
                                ? <Text>No tokens were used. This either is a hardcoded response or saved plan.</Text>
                                : <Text>No tokens have been used in this session yet.</Text>
                        )}
                    </>
                )}
            </div>
            <Divider className={classes.divider} />
        </>
    );
};
