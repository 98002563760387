import { FC, useEffect } from "react";

interface JsonObjectPrettyPrintProps {
    data: any;
    formattedDataCallback?: (formatted: string) => void;
}

export const JsonObjectPrettyPrint: FC<JsonObjectPrettyPrintProps> = ({ data, formattedDataCallback }) => {
    const addIndentation = (s: string, spacePerIndentation: number): string => {
        let spaces = ' ';
        for (let i = 0; i < spacePerIndentation; ++i) {
            spaces += ' ';
        }

        return s.split('\n')
            .map(line => `${spaces}${line}`)
            .reduce((acc, curr) => `${acc}\n${curr}`);
    }

    const SPACE_PER_INDENTATION = 4;
    const format = (value: any): string => {
        if (value == null) {
            return 'null';
        }

        if (typeof value === 'string') {
            let parsed = value;
            try {
                parsed = JSON.parse(value);
            } catch (e) {
                return `"${value}"`;
            }

            if (typeof parsed === 'string') {
                return `"${value}"`;
            }

            return format(parsed);
        }

        if (Array.isArray(value)) {
            let res = `[\n`;
            for (let item of value) {
                res += addIndentation(format(item), SPACE_PER_INDENTATION) + ',\n';
            }
            res += ']';

            return res;
        }

        if (typeof value === 'object') {
            let res = `{\n`;
            for (let key in value) {
                if (!value.hasOwnProperty(key)) {
                    continue;
                }

                res += addIndentation(`"${key}": ${format(value[key])}`, SPACE_PER_INDENTATION) + ',\n';
            }
            res += '}';

            return res;
        }

        return value;
    }

    const formattedData = format(data);
    useEffect(() => {
        if (formattedDataCallback) {
            formattedDataCallback(formattedData);
        }
    }, []);
    return <>
        <pre>{formattedData}</pre>
    </>
}