import { BaseService } from './BaseService';
import {AssistantDTO} from "../models/Assistant/AssistantDTO";
import {AssistantType} from "../models/Assistant/AssistantType";

export class AssistantService extends BaseService {
    private static assistants: AssistantDTO[] | undefined = undefined;

    public getAssistantsAsync = async (accessToken: string): Promise<AssistantDTO[]> => {
        if (AssistantService.assistants !== undefined) {
            return AssistantService.assistants;
        }

        return AssistantService.assistants = await this.getResponseAsync<AssistantDTO[]>(
            {
                commandPath: `assistant`,
                method: 'GET',
            },
            accessToken,
        );
    };

    public async getAssistantByType(type: AssistantType, accessToken: string): Promise<AssistantDTO> {
        if (AssistantService.assistants === undefined) {
            await this.getAssistantsAsync(accessToken);
        }

        const res =  AssistantService.assistants!.find(a => a.type === type);
        if (res === undefined) {
            throw new Error(`Pas d'assistant avec le type ${type}`);
        }

        return res;
    }
}
