import { FC, useState } from "react";
import { ChatMessageLog, ChatMessageLogType } from "./ChatMessageLogService";
import { JsonObjectPrettyPrint } from "../../utils/JsonObjectPrettyPrint";
import { Button, Tooltip } from "@fluentui/react-components";
import { Clipboard20Regular } from "@fluentui/react-icons";

export const ChatMessageLogItem: FC<{ log: ChatMessageLog }> = ({ log }) => {
    const copyOnClick = () => {
        void navigator.clipboard.writeText(formmatedPayload);
    };

    const [formmatedPayload, setFormattedPayload] = useState('');

    return <div style={{ paddingBlock: '.7rem' }}>
        <div>
            <b>{ChatMessageLogType.toReadableString(log.type)}</b>
            <span> {log.createdAt.toLocaleString()}</span>

            <Tooltip content={'Copy text'} relationship="label">
                <Button
                    icon={<Clipboard20Regular />}
                    appearance="transparent"
                    onClick={() => {
                        void copyOnClick();
                    }}
                />
            </Tooltip>
        </div>
        {typeof log.payload === 'string'
            ? log.payload
            : <JsonObjectPrettyPrint data={log.payload} formattedDataCallback={(formatted) => setFormattedPayload(formatted)} />
        }
    </div>;
};