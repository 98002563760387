import { FC, useEffect, useState } from "react";
import { ChatMessageLog, ChatMessageLogService, ChatMessageLogType } from "./ChatMessageLogService";
import { useMsal } from "@azure/msal-react";
import { AuthHelper } from "../../../libs/auth/AuthHelper";
import { ChatMessageLogItem } from "./ChatMessageLogItem";
import { Divider } from "@fluentui/react-components";
import './chat-message-log-tab.css';

export const ChatMessageLogTab: FC<{ messageId: string }> = ({ messageId }) => {

    const { instance, inProgress } = useMsal();
    const chatMessageLogService = new ChatMessageLogService();

    const [state, setState] = useState<'fetching' | 'stable'>('fetching');
    const [receivedLogs, setReceivedLogs] = useState<ChatMessageLog[]>([]);

    const fetchLogs = async () => {
        setState('fetching')
        const accessToken = await AuthHelper.getSKaaSAccessToken(instance, inProgress);
        const logs = await chatMessageLogService.getByMessageIdAsync(messageId, accessToken);

        setReceivedLogs(logs.sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime()));
        setState('stable');
    };

    useEffect(() => {
        void fetchLogs();
    }, []);

    const [receivedLogTypes, setReceivedLogTypes] = useState<{type: ChatMessageLogType, count: number}[]>([]);
    useEffect(() => {
        const logTypes = receivedLogs
            .map(log => log.type);

        const uniqueTypes = logTypes
            .filter((logType, idx) => logTypes.indexOf(logType) === idx);

        setReceivedLogTypes(uniqueTypes.map(u => ({
            type: u,
            count: logTypes.filter(t => t === u).length
        })));
        setSelectedLogTypeFilters([...uniqueTypes]);
    }, [receivedLogs]);

    const [selectedLogTypeFilters, setSelectedLogTypeFilters] = useState<ChatMessageLogType[]>([]);
    const [textFilter, setTextFilter] = useState<string>('');

    const [filteredLogs, setFilteredLogs] = useState<ChatMessageLog[]>([]);
    useEffect(() => {
        let logs = receivedLogs
            .filter(log => selectedLogTypeFilters.includes(log.type));

        if (textFilter != null && textFilter.trim() !== '') {
            const normalizedTextFilter = textFilter.toLowerCase()
                .split('')
                .filter(x => x != ' ')
                .reduce((acc, curr) => acc + curr);

            logs = logs.filter(log => JSON.stringify(log.payload).toLowerCase().includes(normalizedTextFilter))
        }

        setFilteredLogs(logs);
    }, [receivedLogs, selectedLogTypeFilters, textFilter]);

    const selectedFiltersChange = (e: React.ChangeEvent<HTMLInputElement>, logType: ChatMessageLogType) => {
        const filters = selectedLogTypeFilters
            .filter(lt => lt !== logType);

        if (e.target.checked) {
            filters.push(logType);
        }

        setSelectedLogTypeFilters(filters)
    }

    return <div style={{ position: 'relative' }}>
        {state === 'fetching' && <div>Loading...</div>}
        {state === 'stable' && <div>
            <div style={{ position: 'sticky', top: '0', background: 'white', width: '100%', zIndex: '100' }}>
                <button onClick={fetchLogs}>Reload</button>

                <div className="filter-select">
                    {receivedLogTypes.map(logType => {
                        const id = `filter-log-type-${logType.type}`;
                        return <div key={logType.type} className="filter-option">
                            <input id={id} type="checkbox" defaultChecked={true} className="filter-option"
                                onChange={(e) => selectedFiltersChange(e, logType.type)} />
                            <label htmlFor={id}>{ChatMessageLogType.toReadableString(logType.type)} {`(${logType.count})`}</label>
                        </div>
                    }
                    )}
                </div >

                <input type="text" defaultValue='' onChange={(e) => setTextFilter(e.target.value)}
                    style={{ width: '100%', height: '1.2rem' }} />

                <div>{filteredLogs.length} / {receivedLogs.length} logs</div>
                <Divider />
            </div>

            {filteredLogs.map(log =>
                <div key={log.id}>
                    <ChatMessageLogItem log={log} />
                    <Divider />
                </div>
            )}
        </div>
        }
    </div>;
};