import { makeStyles, shorthands, tokens } from '@fluentui/react-components';
import React, { CSSProperties, useEffect, useState } from 'react';
import { GetResponseOptions, useChat } from '../../libs/hooks/useChat';
import { useAppDispatch, useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { FeatureKeys, Features } from '../../redux/features/app/AppState';
import { SharedStyles } from '../../styles';
import { ChatInput } from './ChatInput';
import { ChatHistory } from './chat-history/ChatHistory';
import { ChatMessageType, IChatMessage } from '../../libs/models/ChatMessage';
import { addAlert } from '../../redux/features/app/appSlice';
import { AlertType } from '../../libs/models/AlertType';
import { GenerateConversationReport } from '../../libs/utils/GenerateConversationReport';
import { ExcelFileCreator } from '../../libs/utils/ExcelFileCreator';

const useClasses = makeStyles({
    root: {
        ...shorthands.overflow('hidden'),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
    },
    scroll: {
        ...shorthands.margin(tokens.spacingVerticalXS),
        ...SharedStyles.scroll,
    },
    history: {
        ...shorthands.padding(tokens.spacingVerticalM),
        paddingLeft: tokens.spacingHorizontalM,
        paddingRight: tokens.spacingHorizontalM,
        display: 'flex',
        justifyContent: 'center',
    },
    input: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        ...shorthands.padding(tokens.spacingVerticalS, tokens.spacingVerticalNone),
    },
});

export const ChatRoom: React.FC = () => {
    const classes = useClasses();
    const chat = useChat();

    const { conversations, selectedId } = useAppSelector((state: RootState) => state.conversations);
    const messages = conversations[selectedId].messages;

    const scrollViewTargetRef = React.useRef<HTMLDivElement>(null);
    const [shouldAutoScroll, setShouldAutoScroll] = React.useState(true);

    const [isDraggingOver, setIsDraggingOver] = React.useState(false);
    const onDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setIsDraggingOver(true);
    };
    const onDragLeave = (e: React.DragEvent<HTMLDivElement | HTMLTextAreaElement>) => {
        e.preventDefault();
        setIsDraggingOver(false);
    };

    React.useEffect(() => {
        if (!shouldAutoScroll) return;
        scrollViewTargetRef.current?.scrollTo(0, scrollViewTargetRef.current.scrollHeight);
    }, [messages, shouldAutoScroll]);

    React.useEffect(() => {
        const onScroll = () => {
            if (!scrollViewTargetRef.current) return;
            const { scrollTop, scrollHeight, clientHeight } = scrollViewTargetRef.current;
            const isAtBottom = scrollTop + clientHeight >= scrollHeight - 10;
            setShouldAutoScroll(isAtBottom);
        };

        if (!scrollViewTargetRef.current) return;

        const currentScrollViewTarget = scrollViewTargetRef.current;

        currentScrollViewTarget.addEventListener('scroll', onScroll);
        return () => {
            currentScrollViewTarget.removeEventListener('scroll', onScroll);
        };
    }, []);

    const handleSubmit = async (options: GetResponseOptions) => {
        await chat.getResponse(options);
        setShouldAutoScroll(true);
    };

    const findLastMessageForSuggestion = (messages: IChatMessage[]): IChatMessage => {
        for (let i = messages.length - 1; i >= 0; --i) {
            const message = messages[i];
            if (message.type === ChatMessageType.AdaptiveCard
                || message.type === ChatMessageType.Plan) {
                continue;
            }

            return message;
        }

        return messages[0];
    }

    const [lastMessage, setLastMessage] = useState(findLastMessageForSuggestion(messages));
    useEffect(() => {
        setLastMessage(findLastMessageForSuggestion(messages));
    }, [chat])

    const dispatch = useAppDispatch();

    if (conversations[selectedId].hidden) {
        return (
            <div className={classes.root}>
                <div className={classes.scroll}>
                    <div className={classes.history}>
                        <h3>
                            This conversation is not visible in the app because{' '}
                            {Features[FeatureKeys.MultiUserChat].label} is disabled. Please enable the feature in the
                            settings to view the conversation, select a different one, or create a new conversation.
                        </h3>
                    </div>
                </div>
            </div>
        );
    };


    const onSuggestionButtonClick = async (id: string) => {
        const suggestion = lastMessage.nextMessageSuggestions?.find(s => s.id === id);
        if (suggestion == null) {
            throw new Error(`Suggestion with id ${id} not found`);
        }

        const value = suggestion.text;
        const regex: RegExp = /\[.+\]/;

        // Si la suggestion demande de remplacer une partie alors
        if (regex.test(value)) {
            dispatch(addAlert({
                type: AlertType.Error,
                message: "Ce texte contient une partie à modifier, réécrivez-le vous mêmes"
            }));
            return;
        }


        const chatId = lastMessage.chatId;
        const messageType = ChatMessageType.Message;
        await handleSubmit({
            messageType: messageType,
            value: value,
            chatId: chatId,
            kernelArguments: [
                {
                    key: 'chatId',
                    value: chatId,
                },
                {
                    key: 'messageType',
                    value: messageType.toString(),
                },
            ],
        });
    }

    const suggestionContainerStyle: CSSProperties = {
        display: 'flex',
        justifyContent: 'flex-start',
        columnGap: '1rem',
        rowGap: '.5rem',
        paddingInline: '2rem',
        cursor: 'pointer',
        flexWrap: 'wrap'
    }

    const suggestionButtonStyle: CSSProperties = {
        all: 'unset',
        fontSize: '.9rem',
        border: '3px solid #55a6c8',
        padding: '.3rem',
        borderRadius: '10px',
        backgroundColor: 'white',
        textAlign: 'center'
    }

    const onDownloadReport = () => {
        const reportAsCsv = GenerateConversationReport.generate(messages);
        ExcelFileCreator.saveFile(reportAsCsv, messages[0].chatId);
    }

    return (
        <div className={classes.root} onDragEnter={onDragEnter} onDragOver={onDragEnter} onDragLeave={onDragLeave}>
            <div ref={scrollViewTargetRef} className={classes.scroll}>
                <div className={classes.history}>
                    <ChatHistory messages={messages} />
                </div>
            </div>
            {lastMessage && lastMessage.nextMessageSuggestions
                && <div style={suggestionContainerStyle}>
                    {lastMessage.nextMessageSuggestions.map(s =>
                        <button key={s.id} data-id={s.id} style={suggestionButtonStyle} onClick={() => onSuggestionButtonClick(s.id)}>
                            <span>{s.text}</span>
                        </button>
                    )}
                </div>
            }
            <div className={classes.input}>
                <ChatInput isDraggingOver={isDraggingOver} onDragLeave={onDragLeave} onSubmit={handleSubmit} onDownloadReport={onDownloadReport} />
            </div>
        </div>
    );
};
