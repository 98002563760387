export interface Box {
    columnName: string;
    data: any;
}

export interface Row {
    datas: Box[];
}

export class ExcelFileCreator {
    private rows: Row[] = [];
    private readonly BOX_DELEMITER = ';';
    private readonly ROW_DELEMITER = '\n';

    addLine(row: Row) {
        this.rows.push(row);
    }

    render(columnsOrder: string[]): string {
        columnsOrder = [...columnsOrder];

        const getOrCreateColumnIndex = (columnName: string) => {
            const idx = columnsOrder.indexOf(columnName);
            if (idx !== -1) {
                return idx;
            }

            columnsOrder.push(columnName);
            return columnsOrder.length - 1;
        }

        let res = '';
        for (const row of this.rows) {
            const resolvedRow = [];
            for (const box of row.datas) {
                const columnIdx = getOrCreateColumnIndex(box.columnName);
                if (columnIdx < resolvedRow.length) {
                    resolvedRow[columnIdx] = box.data;
                    continue;
                }

                for (let i = columnIdx - resolvedRow.length + 1; i > 0; --i) {
                    resolvedRow.push(null); // On rajoute des cases vides pour atteindre la taille
                }

                resolvedRow[columnIdx] = box.data;
            }

            if (resolvedRow.length === 0) {
                res += this.ROW_DELEMITER;
            }
            else if (resolvedRow.length === 1) {
                res += `${resolvedRow[0]}${this.ROW_DELEMITER}`;
            }
            else {
                const tmp = resolvedRow.reduce((acc, curr) => acc + this.BOX_DELEMITER + curr);
                res += `${tmp}${this.ROW_DELEMITER}`;
            }
        }

        let header = '';
        if (columnsOrder.length === 0) {
            header += this.ROW_DELEMITER;
        }
        else if (columnsOrder.length === 1) {
            header += `${columnsOrder[0]}${this.ROW_DELEMITER}`;
        }
        else {
            const tmp = columnsOrder.reduce((acc, curr) => acc + this.BOX_DELEMITER + curr);
            header += `${tmp}${this.ROW_DELEMITER}`;
        }

        return header + res;
    }

    static saveFile(data: string, filename: string) {
        const blob = new Blob([data], { type: 'text/plain' });
        const link = document.createElement('a');

        // Set the download attribute with a filename
        link.download = `${filename}.csv`;

        // Create an object URL for the Blob
        link.href = URL.createObjectURL(blob);

        document.body.appendChild(link);

        link.click();
        document.body.removeChild(link);
    }
}