import { AuthorRoles, ChatMessageType, IChatMessage } from "../models/ChatMessage";
import { InvokationTokenUsage, TokenUsage, TokenUsageFunctionNameMap, TokenUsageFunctionOrder } from "../models/TokenUsage";
import { ExcelFileCreator, Row } from "./ExcelFileCreator";

export class GenerateConversationReport {
    static generate(messages: IChatMessage[]): string {
        const csv = new ExcelFileCreator();
        const filtered = messages.filter(m => m.authorRole === AuthorRoles.Bot && m.type === ChatMessageType.Message);

        // Permet de savoir quelle colonne on a croisé
        const usedColumnNames: string[] = []
        const totals: Record<string, number> = {};

        const addToTotals = (key: string, value: number) => {
            if (totals[key] === undefined) {
                totals[key] = value;
            }
            else {
                totals[key] += value;
            }
        }

        for (const message of filtered) {
            const tokenUsage: TokenUsage | undefined = message.tokenUsage;
            if (tokenUsage == null) {
                continue;
            }

            const row: Row = { datas: [] };
            const rowReducedTokenUsage: InvokationTokenUsage = {
                prompt: 0,
                completion: 0,
                total: 0
            }
            for (const key in tokenUsage) {
                const invokationTokenUsage: InvokationTokenUsage = tokenUsage[key];
                const names = GenerateConversationReport.getColumName(key);
                if (!usedColumnNames.includes(key)) {
                    usedColumnNames.push(key);
                }

                row.datas.push({
                    columnName: names.prompt,
                    data: invokationTokenUsage.prompt
                });

                row.datas.push({
                    columnName: names.completion,
                    data: invokationTokenUsage.completion
                });

                row.datas.push({
                    columnName: names.total,
                    data: invokationTokenUsage.total
                });

                addToTotals(names.prompt, invokationTokenUsage.prompt);
                addToTotals(names.completion, invokationTokenUsage.completion);
                addToTotals(names.total, invokationTokenUsage.total);

                rowReducedTokenUsage.prompt += invokationTokenUsage.prompt;
                rowReducedTokenUsage.completion += invokationTokenUsage.completion;
                rowReducedTokenUsage.total += invokationTokenUsage.total;
            }

            row.datas.push({ columnName: 'Total (prompt)', data: rowReducedTokenUsage.prompt });
            addToTotals('Total (prompt)', rowReducedTokenUsage.prompt);

            row.datas.push({ columnName: 'Total (completion)', data: rowReducedTokenUsage.completion });
            addToTotals('Total (completion)', rowReducedTokenUsage.completion);

            row.datas.push({ columnName: 'Total (total)', data: rowReducedTokenUsage.total });
            addToTotals('Total (total)', rowReducedTokenUsage.total);

            csv.addLine(row)
        }

        const totalsAsRow: Row = {
            datas: Object.keys(totals).map(key => {
                return {
                    columnName: key,
                    data: totals[key]
                };
            })
        };

        // Première colonne vide
        totalsAsRow.datas.push({ columnName: 'Total', data: 'Total' });

        csv.addLine(totalsAsRow);
        return csv.render(['Total', ...GenerateConversationReport.getColumnOrders(usedColumnNames), 'Total (prompt)', 'Total (completion)', 'Total (total)']);
    }

    static getColumName(functionName: string) {
        const pretty = TokenUsageFunctionNameMap[functionName];
        return {
            prompt: `${pretty} (prompt)`,
            completion: `${pretty} (completion)`,
            total: `${pretty} (total)`,
        }
    }

    static getColumnOrders(usedColumnNames: string[]) {
        const allColumns = TokenUsageFunctionOrder.reduce((acc, curr) => {
            const names = GenerateConversationReport.getColumName(curr);
            return [
                ...acc,
                names.prompt,
                names.completion,
                names.total,
            ]
        }, [] as string[]);

        const reformatedUsedColumnNames = usedColumnNames.reduce((acc, curr) => {
            const names = GenerateConversationReport.getColumName(curr);
            return [
                ...acc,
                names.prompt,
                names.completion,
                names.total,
            ]
        }, [] as string[]);

        return allColumns.filter(c => reformatedUsedColumnNames.includes(c));
    }
}