import { InvokationTokenUsage, TokenUsageFunctionNameMap, TokenUsageViewDetails } from "../../libs/models/TokenUsage"
import { TokenUsageBar } from "./TokenUsageBar";
import { Brands } from '@fluentui/tokens';
import { semanticKernelBrandRamp } from '../../styles';
import { tokens } from '@fluentui/react-components';

export interface TokenUsageGraphItemProps {
    name: string;
    usage: InvokationTokenUsage;
    globalTotalUsage: number;
}

const contrastColors = [
    tokens.colorPaletteBlueBackground2,
    tokens.colorPaletteBlueForeground2,
    tokens.colorPaletteBlueBorderActive,
];

export const TokenUsageGraphItem: React.FC<TokenUsageGraphItemProps> = ({ name, usage, globalTotalUsage }) => {
    const graphColors = {
        brand: {
            // Color index of semanticKernelBrandRamp array defined in styles.ts
            legend: 120 as Brands,
            index: 120 as Brands,
            getNextIndex: () => {
                const nextIndex = graphColors.brand.index - 20;
                return (nextIndex < 0 ? 160 : nextIndex) as Brands;
            },
        },
        contrast: {
            // Color index of contrastColors array defined above
            legend: 0,
            index: 0,
            getNextIndex: () => {
                return graphColors.contrast.index++ % 3;
            },
        },
    };

    const prompt = {
        usageCount: usage.prompt,
        legendLabel: 'Prompt',
        color: contrastColors[graphColors.contrast.getNextIndex()]
    };

    const completion = {
        usageCount: usage.completion,
        legendLabel: 'Génération',
        color: contrastColors[graphColors.contrast.getNextIndex()]
    };

    const relativeUsage = Math.round(usage.total / globalTotalUsage * 100 * 10) / 10;

    return <>
        <div>
            <b>{TokenUsageFunctionNameMap[name] ?? name}</b>
            <u style={{marginLeft: '.5rem'}}>({usage.prompt} + {usage.completion} = {usage.total})</u>

            <b><i> {relativeUsage}%</i></b>
        </div>
        <div style={{ display: 'flex' }}>
            <TokenUsageBar details={prompt} totalUsage={usage.total} />
            <TokenUsageBar details={completion} totalUsage={usage.total} />
        </div>
    </>
}