/// Information about token usage used to generate bot response.

export interface InvokationTokenUsage {
    prompt: number;
    completion: number;
    total: number;
}

export type TokenUsage = Record<string, InvokationTokenUsage>;

export type TokenUsageView = Record<string, TokenUsageViewDetails>;

export interface TokenUsageViewDetails {
    usageCount: number;
    legendLabel: string;
    color: string;
}

export interface FunctionDetails {
    usageCount: number;
    legendLabel: string;
    color?: string;
}

export const TokenUsageFunctionNameMap: Record<string, string> = {
    userIntentExtraction: 'User Intent Extraction',
    getNextSpeaker: 'Choose agent',
    responseGeneration: 'Response Generation',
    workingMemoryExtraction: 'Working Memory Generation',
    longTermMemoryExtraction: 'Long Term Memory Generation',
    messageSuggestions: 'Message Suggestions',
} as const;

export const TokenUsageFunctionOrder = [ 
    'userIntentExtraction',
    'getNextSpeaker',
    'responseGeneration',
    'longTermMemoryExtraction',
    'workingMemoryExtraction',
    'messageSuggestions',
];
