import { IAsk } from "../../libs/semantic-kernel/model/Ask";
import { BaseService } from "../../libs/services/BaseService";

export enum AutoGenMessageType {
    Message = 0,
    Error = 1,
}

export interface AutoGenConversationDTO {
    chatId: string;
    messages: AutoGenMessageDTO[];
    lastAgenName: string;
}

export type AutoGenMessageRole = 'user' | 'assistant' | 'system' | 'function' | null;

export interface AutoGenMessageDTO {
    id: string;
    dateTime: string;
    type: AutoGenMessageType,
    from: string;
    content: string;
    role: AutoGenMessageRole;
    isHidden: boolean;
}

export class AutoGenService extends BaseService {
    async chatAsync(chatId: string, request: IAsk, accessToken: string): Promise<AutoGenMessageDTO[]> {
        return await this.getResponseAsync<AutoGenMessageDTO[]>(
            {
                commandPath: `api/autogen-chat/${chatId}/messages`,
                method: 'POST',
                body: request
            },
            accessToken,
        );
    }

    async deleteChatAsync(chatId: string, accessToken: string): Promise<void> {
        await this.getResponseAsync(
            {
                commandPath: `api/autogen-chat/${chatId}/messages`,
                method: 'DELETE'
            },
            accessToken,
        );
    }

    async newChatAsync(accessToken: string): Promise<AutoGenConversationDTO> {
        return await this.getResponseAsync(
            {
                commandPath: `api/autogen-chat`,
                method: 'POST'
            },
            accessToken,
        );
    }
}