import { useMsal } from "@azure/msal-react";
import { FC, ReactNode, useEffect, useState } from "react";
import { ChatMemoryService, GetMyLongTermMemoryResponse } from "./ChatMemoryService";
import { AuthHelper } from "../../../libs/auth/AuthHelper";
import './see-my-memories.css';
import { Divider } from "@fluentui/react-components";
import { AppState } from "../../../App";

export const SeeMyMemories: FC<{ appState: AppState }> = ({ appState }) => {
    const { instance, inProgress } = useMsal();
    const chatMemoryService = new ChatMemoryService();

    const [state, setState] = useState<'loading' | 'stable'>('loading');
    const [fetchedMemories, setFetchedMemories] = useState<GetMyLongTermMemoryResponse[]>([]);
    const [filteredMemories, setFilteredMemories] = useState<GetMyLongTermMemoryResponse[]>([]);

    const fetch = async () => {
        setState('loading');

        const accessToken = await AuthHelper.getSKaaSAccessToken(instance, inProgress);
        const response = await chatMemoryService.getMyMemoriesASync(accessToken);
        // decroissant
        setFetchedMemories(response.sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime()));
        setState('stable');
    };

    useEffect(() => {
        void fetch();
    }, [])

    const [textFilter, setTextFilter] = useState<string>('');
    const normalizedText = (s: string) => s.trim().toLowerCase();
    useEffect(() => {
        if (textFilter == null || textFilter.trim() !== '') {
            setFilteredMemories(fetchedMemories);
        }

        const normalizedTextFilter = normalizedText(textFilter.trim().toLowerCase());
        const memories = fetchedMemories.filter(m => normalizedText(m.content).includes(normalizedTextFilter))

        setFilteredMemories(memories);
    }, [fetchedMemories, textFilter]);

    const formatContent = (input: string) => {
        const splited = input.split(':');

        if (splited.length < 2) {
            return input;
        }

        const label = splited[0];
        const content = splited.length === 2
            ? splited[1]
            : splited.slice(1).reduce((acc, curr) => `${acc}:${curr}`);

        return <span><b>{label}: </b>{content}</span>
    }

    const deleteMemoriesAsync = async () => {
        setState('loading');

        const accessToken = await AuthHelper.getSKaaSAccessToken(instance, inProgress);
        await chatMemoryService.deleteMyMemoriesAsync(accessToken);
        const response = await chatMemoryService.getMyMemoriesASync(accessToken);
        // decroissant
        setFetchedMemories(response.sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime()));
        setState('stable');
    }

    return <div style={{ paddingInline: '1rem' }}>
        <p>
            {`Après chaque message l'IA va analyser ce qui à été dit pour essayer de stocker des informations personelles à l'utilisateur,
            ses préférences par exemple.`}
            <br />
            {`Le but est d'apprendre l'utilisateur et de pouvoir réutiliser ces informations dans d'autre conversations.`}
        </p>

        {state === 'loading'
            ? 'Loading...'
            : <div >
                <div className="memory-header-container">
                    <div>
                        <button onClick={fetch}>Refresh</button>
                        <button onClick={deleteMemoriesAsync}>Delete all memories (pas de message de confirmation)</button>
                    </div>
                    <input type="text" defaultValue='' onChange={(e) => setTextFilter(e.target.value)}
                        style={{ width: '100%', height: '1.2rem' }} />
                    <span>{filteredMemories.length} / {fetchedMemories.length} résultats</span>
                </div>
                <ul className="memories-container">
                    {filteredMemories.map(m => {
                        return <li key={m.id} className="memory-item">
                            <div>
                                <span className="memory-date">{`[${m.createdAt.toLocaleString()}]`} </span>
                                {formatContent(m.content)}
                            </div>
                            <Divider />
                        </li>;
                    })}
                </ul>
            </div>
        }
    </div>
};