import { Action, Dispatch } from "@reduxjs/toolkit";
import { AlertType } from "../libs/models/AlertType";
import { BaseService } from "../libs/services/BaseService";
import { addAlert } from "../redux/features/app/appSlice";

export class AdaptiveCardActionService extends BaseService {
    async dispatchAsync(actionData: any, accessToken: string, dispatch: Dispatch<Action>): Promise<void> {
        try {
            await this.getResponseAsync(
                {
                    commandPath: `api/adaptive-card/dispatch`,
                    method: 'POST',
                    body: actionData
                },
                accessToken,
            );


            dispatch(addAlert({
                message: "Votre action c'est bien réalisé",
                type: AlertType.Success,
            }));
        } catch (e: any) {
            dispatch(addAlert({
                message: "Une erreur est survenue durant l'action",
                type: AlertType.Error
            }));
        }
    }
}