import { FC, useEffect, useState } from "react";
import ReactWebChat, { createDirectLine } from "botframework-webchat";
import { DirectLine } from "botframework-directlinejs";
import { AuthHelper } from "../../libs/auth/AuthHelper";
import { useMsal } from "@azure/msal-react";
import { BotFrameworkService } from "../../services/BotFrameworkService";
import { AppState } from "../../App";

export const BotFrameworkPage: FC<{ appState: AppState }> = (props) => {
    const [directLine, setDirectLine] = useState<DirectLine | null>(null);
    const { instance, inProgress } = useMsal();

    const botFrameworkService = new BotFrameworkService();
    const startBot = async () => {
        const accessToken = await AuthHelper.getSKaaSAccessToken(instance, inProgress);
        if (accessToken == null || accessToken == "") {
            console.log('No token still available')
            return;
        }

        const authInfos = await botFrameworkService.getNewConversationAuthAsync(accessToken);

        const directLineInstance: DirectLine = createDirectLine({
            secret: authInfos.token,
            token: authInfos.token,
            domain: 'https://europe.directline.botframework.com/v3/directline',
            conversationId: authInfos.conversationId,
            streamUrl: authInfos.streamUrl
        });
        console.log('directLineInstance', directLineInstance)
        setDirectLine(directLineInstance);
    }

    useEffect(() => {
        if (directLine != null) {
            return;
        }

        void startBot();
    }, [directLine]);

    const restartChat = () => {
        setDirectLine(null);
    }

    return (
        <>
            <button onClick={() => restartChat()}>Restart chat</button>
            <div style={{ display: 'flex' }}>
                <div style={{ width: '80%', height: '90vh', border: '2px solid black' }}>
                    {directLine ? (
                        <ReactWebChat directLine={directLine} />
                    ) : (
                        <div>Loading chat...</div>
                    )}
                </div>
                <span>{directLine
                    ? "Chat Started"
                    : "Chat is Starting"}</span>

                {/* {token &&
                   <iframe src={`https://europe.webchat.botframework.com/embed/socdev-bot-we?s=${token}`}
                        style={{width: '50%', height: '90vh', border: '2px solid black'}}></iframe>
                } */}

            </div>
        </>
    );
}


