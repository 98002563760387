import { GetBotFrameworkTokenResponseDTO } from "../libs/models/DTO/BotFramework/GetBotFrameworkTokenResponseDTO";
import { BaseService } from "../libs/services/BaseService";


export class BotFrameworkService extends BaseService
{
    async getNewConversationAuthAsync(accessToken: string): Promise<GetBotFrameworkTokenResponseDTO> {
        return await this.getResponseAsync<GetBotFrameworkTokenResponseDTO>(
            {
                commandPath: `api/bot-framework/token`,
                method: 'GET',
            },
            accessToken,
        );
    }
}