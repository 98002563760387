import {FC, useEffect, useState} from "react";
import {AppState} from "../../App";
import {BackendProbe, ChatView, Error, Loading} from "../views";
import * as React from "react";
import {AuthHelper} from "../../libs/auth/AuthHelper";
import { RootState } from "../../redux/app/store";
import { useAppSelector } from "../../redux/app/hooks";
import { log } from "console";
import { ConversationsState } from "../../redux/features/conversations/ConversationsState";

export interface ChatGlobalPageProps {
    appState: AppState;
    setAppState: (state: AppState) => void
}

export const ChatGlobalPage: FC<ChatGlobalPageProps> = (props: ChatGlobalPageProps) => {
    const appState = props.appState;
    const setAppState = props.setAppState;

    const onBackendFound = React.useCallback(() => {
        setAppState(
            AuthHelper.isAuthAAD()
                ? // if AAD is enabled, we need to set the active account before loading chats
                AppState.SettingUserInfo
                : // otherwise, we can load chats immediately
                AppState.LoadingChats
        );
    }, [appState])


    const conversationState: ConversationsState = useAppSelector((state: RootState) => state.conversations);

    return (
        <>
            {appState === AppState.ProbeForBackend && <BackendProbe onBackendFound={onBackendFound}/>}
            {appState === AppState.SettingUserInfo && (
                <Loading text={'Hang tight while we fetch your information...'}/>
            )}
            {appState === AppState.ErrorLoadingUserInfo && (
                <Error text={'Unable to load user info. Please try signing out and signing back in.'}/>
            )}
            {appState === AppState.ErrorLoadingChats && (
                <Error text={'Unable to load chats. Please try refreshing the page.'}/>
            )}
            {appState === AppState.LoadingChats && <Loading text={`Loading chats...`}/>}
            {appState === AppState.Chat && <ChatView/>}
        </>
    );
};
