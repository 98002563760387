import { BaseService } from "../../../libs/services/BaseService";

export enum ChatMessageLogType {
    Unknown = 0,
    IncludeMemoryInMessage = 1,
    GeneratedMemory = 2,
    IgnoredMemory = 3,
    PluginCall = 4,
    TokenUsage = 5
}

export namespace ChatMessageLogType {
    export function toReadableString(type: ChatMessageLogType): string {
        switch (type) {
            case ChatMessageLogType.Unknown:
                return 'Unknown';
            case ChatMessageLogType.IncludeMemoryInMessage:
                return 'IncludeMemoryInMessage';
            case ChatMessageLogType.GeneratedMemory:
                return 'GeneratedMemory';
            case ChatMessageLogType.IgnoredMemory:
                return 'IgnoredMemory';
            case ChatMessageLogType.PluginCall:
                return 'PluginCall';
            case ChatMessageLogType.TokenUsage:
                return 'TokenUsage';
            default:
                throw new Error(`Unknow ChatMessageLogType ${type}`)
        }
    }
}

export interface ChatMessageLog {
    id: string;
    chatId: string;
    type: ChatMessageLogType;
    payload: any;
    createdAt: Date;
}


export interface ChatMessageLogDTO {
    id: string;
    chatId: string;
    type: ChatMessageLogType;
    payload: string;
    createdAt: string;
}

export class ChatMessageLogService extends BaseService {
    async getByMessageIdAsync(messageId: string, accessToken: string): Promise<ChatMessageLog[]> {
        var logs = await this.getResponseAsync<ChatMessageLogDTO[]>(
            {
                commandPath: `api/message-logs/${messageId}`,
                method: 'GET'
            },
            accessToken,
        );

        return logs.map(l => ({
            ...l,
            payload: JSON.parse(l.payload),
            createdAt: new Date(l.createdAt)
        }));
    }
}