import { BaseService } from "../../../libs/services/BaseService";

export interface GetMyLongTermMemoryResponseDTO {
    id: string;
    content: string;
    createdAt: string;
}


export type GetMyLongTermMemoryResponse = {
    id: string;
    content: string;
    createdAt: Date;
}

export class ChatMemoryService extends BaseService {
    async getMyMemoriesASync(accessToken: string): Promise<GetMyLongTermMemoryResponse[]> {
        const memories = await this.getResponseAsync<GetMyLongTermMemoryResponseDTO[]>(
            {
                commandPath: `api/memories/me`,
                method: 'GET'
            },
            accessToken,
        );

        return memories.map(m => ({
            ...m,
            createdAt: new Date(m.createdAt)
        }));
    }

    async deleteMyMemoriesAsync(accessToken: string): Promise<GetMyLongTermMemoryResponse[]> {
        const memories = await this.getResponseAsync<GetMyLongTermMemoryResponseDTO[]>(
            {
                commandPath: `api/memories/me`,
                method: 'DELETE'
            },
            accessToken,
        );

        return memories.map(m => ({
            ...m,
            createdAt: new Date(m.createdAt)
        }));
    }
}